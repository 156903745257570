<template>
  <v-container
    fluid
    class="fill-height justify-center"
    style="cursor: pointer;"
    @click="$router.push('/login')"
  >
  </v-container>
</template>

<route>
{
  "redirect": "/stage/main",
  "meta": {
    "auth": false
  }
}
</route>

<script>
export default {
  name: 'Index',
  layout: 'guest',
  components: {}
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.intro {
  width: 460px;
  height: auto;
  max-width: 100%;
  margin-top: 40px;
}
</style>
